<script lang="ts" setup>
import { useMeStore } from '@/stores/me'
import { ProfileType } from '@/types/me'
import type { Profile } from '@/types/me'
import { currentProfileName } from '@/utils/profile'
import { RequestStatus } from '@/utils/restStore'
import { ref, watch } from 'vue'

import UIIcon from '@/components/generic/atoms/UIIcon.vue'

const meStore = useMeStore()

const selectedId = ref(meStore.selectedClient || meStore.selectedCompany)
const toggleOptions = ref(false)

watch(
  () => meStore.selectedClient,
  async (value) => {
    if (value) {
      selectedId.value = value
    }
  }
)

watch(
  () => meStore.selectedCompany,
  async (value) => {
    if (value) {
      selectedId.value = value
    }
  }
)

const selectProfile = (profile: Profile) => {
  meStore.changeSelectedProfile(profile as Profile)
  toggleOptions.value = false
}

const getSelectedProfileName = (profiles: Profile[]) => {
  if (meStore.selectedClient) {
    const profile = profiles.find((profile) => profile.clientId === meStore.selectedClient)
    return `${profile?.firstName} ${profile?.lastName}`
  } else if (meStore.selectedCompany) {
    return profiles.find((profile) => profile.company?.companyId === meStore.selectedCompany)?.company?.legalName
  } else return ''
}
</script>

<template>
  <div
    v-if="meStore.profiles.status === RequestStatus.RequestLoaded && meStore.profiles.data.length > 1"
    class="lg:mx-8 lg:my-2 lg:mb-10 transform -translate-y-4 lg:transform-none w-full md:w-fit lg:w-auto relative z-50">
    <div
      @click="toggleOptions = !toggleOptions"
      class="cursor-pointer lg:border lg:border-white lg:border-opacity-20 w-full md:w-auto rounded-md pl-2 py-3 pr-8 text-sm bg-sage-200 lg:bg-transparent lg:text-white h-12">
      <div v-if="selectedId" class="grid grid-cols-[auto_1fr] overflow-hidden w-full">
        <UIIcon
          :route-name="meStore.selectedCompany ? 'company' : 'user'"
          class="transform scale-75 mr-1 w-6 min-w-6" />

        <span class="w-max text-ellipsis">{{ getSelectedProfileName(meStore.profiles.data) }}</span>
      </div>
    </div>
    <div
      v-if="toggleOptions"
      class="absolute bg-sage-200 lg:bg-emerald-950 shadow-xl lg:text-white cursor-pointer min-w-full border border-sage-300 lg:border-white/20 border-opacity-20 rounded-lg transform translate-y-1">
      <div
        class="mx-2 py-3 text-sm border-b last:border-b-0 border-sage-300 md:border-white/10 border-opacity-10 hover:opacity-80 flex justify-start items-center"
        v-for="profile in meStore.profiles.data"
        :key="profile.company?.companyId || profile.clientId"
        @click="selectProfile(profile)">
        <UIIcon
          :route-name="profile.profileType === ProfileType.B2B ? 'company' : 'user'"
          class="transform scale-75 mr-1" />
        <span class="w-max text-ellipsis"> {{ currentProfileName(profile) }}</span>
      </div>
    </div>

    <UIIcon
      route-name="chevron-down"
      class="absolute top-3 right-2 lg:text-white"
      :class="{ 'transform rotate-180': toggleOptions }" />
  </div>
</template>

<style scoped>
select {
  -webkit-appearance: none;
}

@media screen and (min-width: 1024px) {
  select {
    width: -webkit-fill-available;
  }
}

select option {
  background: rgb(255, 157, 0);
  color: red;
}
</style>
