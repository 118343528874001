import { defineStore } from 'pinia'

interface State {
  successShowed: boolean
  message: string
}

const initialState = (): State => ({
  successShowed: false,
  message: ''
})

export const useSuccessStore = defineStore({
  id: 'show_success',
  state: initialState,
  actions: {
    showSuccessMessage(message: string) {
      this.successShowed = true
      this.message = message
    },
    hideSuccess() {
      this.successShowed = false
    }
  }
})
