<script setup lang="ts">
import { i18n } from '@/main'
import { profileChangeIsAvailable } from '@/utils/profile'
import { ref } from 'vue'
import { RouterLink } from 'vue-router'
import { useRoute } from 'vue-router'

import ProfileSelect from '@/components/generic/atoms/ProfileSelect.vue'
import MainMenuItem from '@/components/generic/molecules/MainMenuItem.vue'
import SubMenuMobile from '@/components/generic/organisms/SubMenuMobile.vue'

import AccountIcon from '@/assets/svg/nav-icons/account.svg?component'
import AdvisorIcon from '@/assets/svg/nav-icons/advisor.svg?component'
import ClaimsIcon from '@/assets/svg/nav-icons/claims.svg?component'
import ContractsIcon from '@/assets/svg/nav-icons/contracts.svg?component'
import HomeIcon from '@/assets/svg/nav-icons/home.svg?component'
import MoreIcon from '@/assets/svg/nav-icons/more.svg?component'
import ChevronRight from '@/assets/svg/ui/chevron-right.svg?component'
import YagoLogo from '@/assets/svg/yago-logo.svg?component'

/** @ts-ignore */
const $t = i18n.global.t

const route = useRoute()

const items = [
  { routeName: 'home', icon: HomeIcon },
  { routeName: 'contracts', icon: ContractsIcon, rootPath: $t('paths.contracts') },
  { routeName: 'advisor', icon: AdvisorIcon },
  { routeName: 'claims', icon: ClaimsIcon, rootPath: $t('paths.claims'), hideMobile: true },
  { routeName: 'account', icon: AccountIcon, rootPath: $t('paths.account'), hideMobile: true }
]

const showMobileSubMenu = ref(false)

const closeMobileSubmenu = () => {
  showMobileSubMenu.value = false
}
const openMobileSubmenu = () => {
  showMobileSubMenu.value = true
}
</script>

<template>
  <nav class="z-10">
    <YagoLogo class="hidden text-white lg:mx-auto lg:mb-8 lg:mt-6 lg:block" />
    <ProfileSelect class="hidden lg:block" v-if="profileChangeIsAvailable(route.name as string)" />

    <ul class="m-auto grid max-w-lg grid-cols-4 lg:block">
      <MainMenuItem
        v-for="{ routeName, icon, rootPath, hideMobile } in items"
        :key="routeName"
        :route-name="routeName"
        :icon="icon"
        :root-path="rootPath ? rootPath : null"
        :hide-mobile="hideMobile" />

      <!-- mobile submenu -->
      <li class="text-center text-xs text-sage-500 md:text-sm lg:hidden" @click="openMobileSubmenu()">
        <div class="flex flex-col items-center justify-center h-full cursor-pointer">
          <Suspense>
            <component :is="MoreIcon" class="h-[10px] mt-2 lg:mt-0" />
          </Suspense>
          <span class="mt-2 lg:mt-0">{{ $t('general.main_menu.more') }}</span>
        </div>
        <SubMenuMobile :open="showMobileSubMenu" @close="closeMobileSubmenu()">
          <li
            v-for="item in items.filter((item) => item.hideMobile)"
            :key="item.routeName"
            class="flex items-center my-1 rounded-xl bg-sage-200 w-full"
            @click="closeMobileSubmenu()">
            <RouterLink
              class="flex items-center justify-between w-full p-6"
              :to="{ name: item.routeName, params: { locale: $i18n.locale } }">
              <Suspense>
                <component :is="item.icon" />
              </Suspense>
              <span class="ml-3 text-left text-sm font-medium leading-[130%] text-emerald-950">
                {{ $t(`general.main_menu.${item.routeName}`) }}
              </span>
              <ChevronRight class="opacity-30 ml-auto" />
            </RouterLink>
          </li>
        </SubMenuMobile>
      </li>
    </ul>
  </nav>
</template>
