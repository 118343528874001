<script setup lang="ts">
import { useMeStore } from '@/stores/me'
import { ref } from 'vue'

import UIIcon from '@/components/generic/atoms/UIIcon.vue'
import Modal from '@/components/generic/molecules/Modal.vue'
import OnBoardingStep1 from '@/components/home/molecules/OnBoardingStep1.vue'
import OnBoardingStep2 from '@/components/home/molecules/OnBoardingStep2.vue'
import OnBoardingStep3 from '@/components/home/molecules/OnBoardingStep3.vue'
import OnBoardingStep4 from '@/components/home/molecules/OnBoardingStep4.vue'
import OnBoardingStepMarsh from '@/components/home/molecules/OnBoardingStepMarsh.vue'

const meStore = useMeStore()

const currentStep = ref(meStore.marshClient ? 0 : 1)
const stepsLength = 4

const properties = defineProps<{
  closeModal: Function
}>()

const toNextStep = () => {
  if (currentStep.value === stepsLength) properties.closeModal()
  else currentStep.value += 1
}
</script>

<template>
  <Modal
    :open="true"
    :close-button="false"
    :variant="'fullOnMobile'"
    class="py-6 px-2 xs:py-3 xs:!max-w-3xl xs:!w-3xl xs:px-4 h-[calc(100dvh)] xs:h-fit"
    :class="'h-full'">
    <template v-slot:custom-content>
      <OnBoardingStepMarsh v-if="currentStep === 0" />
      <OnBoardingStep1 v-if="currentStep === 1" />
      <OnBoardingStep2 v-if="currentStep === 2" />
      <OnBoardingStep3 v-if="currentStep === 3" />
      <OnBoardingStep4 v-if="currentStep === 4" />

      <div class="flex justify-between w-full items-center absolute p-6 bottom-3 xs:w-1/2 xs:left-3 xs:p-5">
        <button class="text-blue-500 font-medium text-sm" @click="properties.closeModal()">
          {{ $t('pages.home.onboarding_modal.ignore') }}
        </button>
        <div class="flex">
          <div
            v-for="step in stepsLength"
            :key="step"
            class="w-2 h-2 rounded bg-sage-300 m-[3px] cursor-pointer"
            :class="{ 'bg-sage-500': step === currentStep }"
            @click="currentStep = step"></div>
        </div>
        <button @click="toNextStep()"><UIIcon route-name="right_arrow" /></button>
      </div>
    </template>
  </Modal>
</template>
