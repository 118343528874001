<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { useMeStore } from '@/stores/me'
import { RequestStatus } from '@/utils/restStore'
import { ref, watch } from 'vue'
import { RouterView } from 'vue-router'
import { useRoute } from 'vue-router'

import OnBoarding from '@/components/home/organisms/OnBoarding.vue'

import ProfileSelect from './components/generic/atoms/ProfileSelect.vue'
import ProfilesModal from './components/generic/organisms/ProfilesModal.vue'
import AppStructure from './components/generic/templates/AppStructure.vue'
import { profileChangeIsAvailable } from './utils/profile'

const authStore = useAuthStore()
const meStore = useMeStore()
let showOnboardingModal = ref(false)

const closeOnbardingModal = () => {
  showOnboardingModal.value = false
  if (!localStorage.getItem('operatorId')) meStore.postOnboarded()
}

const route = useRoute()

const mountZendeskScript = async () => {
  //* zendesk integration script

  const script = document.createElement('script')
  script.async = true
  script.src = 'https://static.zdassets.com/ekr/snippet.js?key=b5f14a37-0817-4b2a-862c-029679280e15'
  script.id = 'ze-snippet'
  document.head.appendChild(script)
  script.onload = async () => {
    await setToken()
  }
}

const setToken = async () => {
  await meStore.fetchToken()
  if (meStore.meToken.status === RequestStatus.RequestLoaded && document.getElementById('ze-snippet')) {
    //* zendesk login authentication script
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.innerText = `zE('messenger', 'loginUser', function (callback) {
        callback("${meStore.meToken.data.token}")
      });`
    document.body.appendChild(script)
  }
}

watch(
  () => authStore.isAuthenticated,
  async (value) => {
    if (value) {
      await mountZendeskScript()
      await meStore.fetchProfiles()
    }
  }
)

watch(
  () => meStore.showOnboarding,
  async (value) => {
    if (!localStorage.getItem('operatorId')) showOnboardingModal.value = value
  }
)

watch(
  () => meStore.isUnfit,
  async (value) => {
    if (value) {
      if (document.getElementById('ze-snippet')) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.innerText = "zE('messenger', 'hide');"
        script.async = true
        document.body.appendChild(script)
      }
    }
  }
)
</script>

<template>
  <ProfilesModal
    v-if="meStore.me.status === RequestStatus.RequestLoaded && !meStore.selectedClient && !meStore.selectedCompany" />

  <OnBoarding :close-modal="closeOnbardingModal" v-if="showOnboardingModal" />
  <AppStructure>
    <ProfileSelect class="lg:hidden" v-if="profileChangeIsAvailable(route.name as string)" />
    <RouterView />
  </AppStructure>
</template>
