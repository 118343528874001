import { defineStore } from 'pinia'

interface State {
  errorShowed: boolean
  message: string | null
}

const initialState = (): State => ({
  errorShowed: false,
  message: null
})

export const useErrorStore = defineStore({
  id: 'show_error',
  state: initialState,
  actions: {
    showError() {
      this.errorShowed = true
    },
    showSpecificError(message: string) {
      this.errorShowed = true
      this.message = message
    },
    hideError() {
      this.errorShowed = false
    }
  }
})
