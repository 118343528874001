<template>
  <component :is="icon" />
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'

const properties = defineProps<{ routeName: string }>()
/** Unfortunately, alias path is not handled here, we need to keep this ugly ../.. */
const icon = defineAsyncComponent(() => import(`../../../assets/svg/ui/${properties.routeName}.svg`))
</script>
