<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { useErrorStore } from '@/stores/error'
import { useSuccessStore } from '@/stores/success'
import { watch } from 'vue'
import { useRoute } from 'vue-router'

import BannerFailedRequest from '@/components/generic/molecules/BannerFailedRequest.vue'
import BannerSucceedRequest from '@/components/generic/molecules/BannerSucceedRequest.vue'
import MainMenu from '@/components/generic/organisms/MainMenu.vue'

const authStore = useAuthStore()
const errorStore = useErrorStore()
const successtore = useSuccessStore()

const route = useRoute()

watch(
  () => route.name,
  () => {
    const container = document.getElementById('target')
    if (container) {
      container.scrollTop = 0
    }
  }
)
</script>

<template>
  <BannerFailedRequest v-if="errorStore.errorShowed" :message="errorStore.message || $t('general.error_message')" />
  <BannerSucceedRequest v-if="successtore.successShowed" :message="successtore.message" />

  <main
    v-if="authStore.isAuthenticated"
    class="lg:fixed lg:grid lg:h-[calc(100dvh)] lg:w-full lg:grid-cols-[18rem_1fr] lg:gap-8 lg:p-8 max-h-[calc(100dvh)] overflow-y-scroll"
    id="target">
    <div>
      <MainMenu
        class="fixed bottom-0 w-full border-t border-sage-300 bg-white pb-8 pt-4 lg:w-[18rem] lg:h-fit lg:top-12 lg:border-0 lg:bg-transparent" />
    </div>

    <div class="w-full lg:w-full lg:rounded-2xl lg:bg-white px-4 py-12 md:p-8">
      <!-- authenticated views -->
      <slot></slot>
    </div>
  </main>

  <main v-else>
    <!-- unauthenticated views -->
    <slot></slot>
  </main>
</template>
