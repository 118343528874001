<script setup lang="ts"></script>

<template>
  <div class="xs:flex flex-row-reverse gap-8 h-full">
    <div class="rounded-2xl overflow-hidden xs:w-1/2">
      <img src="@/assets/png/team.jpg" alt="Yago team" class="min-w-full object-cover" />
    </div>
    <div class="my-8 xs:my-0 h-full xs:pb-12 xs:w-1/2 text-left">
      <h2 class="font-medium text-2xl leading-7">{{ $t('pages.home.onboarding_modal.step_marsh.title') }}</h2>
      <p class="text-sm my-4 mb-8">
        {{ $t('pages.home.onboarding_modal.step_marsh.description') }}
      </p>
      <a
        class="text-white text-s cta font-medium"
        target="_blank"
        :href="$t('pages.home.onboarding_modal.step_marsh.cta_link')">
        <ArrowIcon class="my-auto opacity-40" /> {{ $t('pages.home.onboarding_modal.step_marsh.cta_label') }}</a
      >
    </div>
  </div>
</template>
