import { ProfileType } from '@/types/me'
import type { Profile } from '@/types/me'

export const currentProfileName = (profile: Profile) => {
  if (profile.profileType === ProfileType.B2B) {
    return profile.company?.legalName
  }
  if (profile.profileType === ProfileType.B2C) {
    if (profile.firstName || profile.lastName) return `${profile.firstName} ${profile.lastName}`
    else return profile.email
  }
}

export const profileChangeIsAvailable = (routeName: string) => {
  return ['home', 'contracts', 'advisor', 'claims', 'account'].find((route) => route === routeName) !== undefined
}
