<script setup lang="ts">
import { useMeStore } from '@/stores/me'
import { ProfileType } from '@/types/me'
import type { Profile } from '@/types/me'
import { currentProfileName } from '@/utils/profile'
import { RequestStatus } from '@/utils/restStore'

import UIIcon from '@/components/generic/atoms/UIIcon.vue'
import Modal from '@/components/generic/molecules/Modal.vue'

const meStore = useMeStore()

const selectProfile = (profile: Profile) => {
  meStore.changeSelectedProfile(profile)
}
</script>

<template>
  <Modal :open="true" :close-button="false" v-if="meStore.profiles.status === RequestStatus.RequestLoaded">
    <template v-slot:custom-content>
      <div class="m-1">
        <h1 class="font-semibold text-2xl">{{ $t('general.select_profile.title') }}</h1>
        <p class="text-sm mt-2 mb-8">{{ $t('general.select_profile.description') }}</p>
        <div class="bg-sage-200 p-6 rounded-xl w-full">
          <div
            v-for="profile in meStore.profiles.data"
            :key="profile.company?.companyId || profile.clientId"
            class="grid grid-cols-[30px_auto_30px] py-5 first:pt-0 last:pb-0 last:border-b-0 border-b-[1px] border-b-sage-300 gap-2 cursor-pointer"
            @click="selectProfile(profile)">
            <UIIcon :route-name="profile.profileType === ProfileType.B2B ? 'company' : 'user'" />
            <div class="text-left text-sm mt-1">
              {{ currentProfileName(profile) }}
            </div>
            <UIIcon route-name="link-chevron-right" />
          </div>
        </div>

        <p class="text-xs opacity-50 mt-8">
          {{ $t('general.select_profile.explanation') }}
        </p>
      </div>
    </template>
  </Modal>
</template>
