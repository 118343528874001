import { useAuthStore } from '@/stores/auth'
import type { AxiosResponse } from 'axios'
import { useRouter } from 'vue-router'

import Api from '@/services/api'

export enum RequestStatus {
  RequestLoading,
  RequestError,
  RequestLoaded,
  NoRequest
}

type NoneState = { status: RequestStatus.NoRequest }
type LoadingState = { status: RequestStatus.RequestLoading }
type ErrorState = { status: RequestStatus.RequestError; errors: string[] }
type LoadedState<Data> = { status: RequestStatus.RequestLoaded; data: Data }

export type RequestState<Data> = LoadingState | ErrorState | LoadedState<Data> | NoneState

export const initialRequestState = <Data>(): RequestState<Data> => ({
  status: RequestStatus.NoRequest
})

interface ApiService {
  errorToMessages: (error: unknown) => string[]
  isUnauthorizedError: (error: unknown) => boolean
}

export const handleRequest = async <Data>(
  request: () => Promise<AxiosResponse<{ data: Data }>>,
  updateState: (data: RequestState<Data>) => void,
  apiService: ApiService = Api
) => {
  try {
    updateState({ status: RequestStatus.RequestLoading })
    const {
      data: { data }
    } = await request()
    updateState({
      status: RequestStatus.RequestLoaded,
      data
    })
  } catch (error) {
    updateState({
      status: RequestStatus.RequestError,
      errors: apiService.errorToMessages(error)
    })
    if (apiService.isUnauthorizedError(error)) {
      const authStore = useAuthStore()
      authStore.setAuthenticated(false)
      const router = useRouter()
      if (router) router.push({ name: 'login' })
    }
  }
}
